<template>
  <v-dialog
    :max-width="res && expanded ? 900 : 456"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-sheet
      color="white"
      class="LdapTestConnectionDialog"
      min-height="276"
    >
      <h1 class="LdapTestConnectionDialog__title">
        <span v-text="title" />
        <v-btn
          v-if="err != null || res != null"
          icon
          @click="expanded = !expanded"
        >
          <v-icon v-text="'mdi-information-variant'" />
        </v-btn>
      </h1>

      <v-progress-linear
        v-show="res == null || !expanded"
        class="LdapTestConnectionDialog__progress"
        :color="err ? 'error' : '#2AD9A4'"
        :indeterminate="inProgress"
        :value="100"
      />

      <div
        v-show="res == null || !expanded"
        class="LdapTestConnectionDialog__res"
        :class="{
          'LdapTestConnectionDialog__res--success': res != null,
          'LdapTestConnectionDialog__res--error': err != null,
        }"
      >
        <v-icon
          size="40"
          :color="err ? 'error' : '#2AD9A4'"
          v-text="err ? 'mdi-close' : 'mdi-check'"
        />
      </div>

      <v-expand-transition>
        <div
          v-if="err != null"
          v-show="expanded"
          class="LdapTestConnectionDialog__error-message"
          v-text="err"
        />
      </v-expand-transition>

      <v-expand-transition>
        <v-data-table
          v-if="res != null"
          v-show="expanded"
          height="clamp(200px, calc(70vh - 150px), 600px)"
          class="LdapTestConnectionDialog__success-table"
          :items="res.items || []"
          :headers="tableHeaders"
          :items-per-page="-1"
          hide-default-footer
          fixed-header
          dense
        >
          <template #body.append>
            <tr>
              <th>{{ $t('service.Total') }}</th>
              <th
                colspan="4"
                v-text="res
                  ? $tc('service.recordsN', res.totalCount, { n: res.totalCount > 100 ? '100+' : res.totalCount })
                  : '...'"
              />
            </tr>
          </template>
        </v-data-table>
      </v-expand-transition>

      <div
        v-if="res == null && err == null"
        class="LdapTestConnectionDialog__actions"
      >
        <v-btn
          outlined
          color="primary"
          @click="close"
        >
          {{ $t('service.Cancel') }}
        </v-btn>
      </div>

      <v-btn
        absolute
        icon
        top
        right
        @click="close"
      >
        <v-icon v-text="'mdi-close'" />
      </v-btn>
    </v-sheet>
  </v-dialog>
</template>

<script>
import * as R from 'ramda'

export default {
  name: 'LdapTestConnectionDialog',

  inheritAttrs: false,

  props: {
    value: { type: Boolean, default: true },
    ldapConfigForm: { type: Object, default: null },
  },

  data() {
    return {
      expanded: false,
    }
  },

  computed: {
    res() { return this.$store.state.ldapConfigEditPage.testConfigRes },
    err() { return this.$store.state.ldapConfigEditPage.testConfigError },

    inProgress() { return !this.res && !this.err },

    title() {
      return R.cond([
        [([res, _]) => !!res, () => this.$t('service.ConnectionEstablished')],
        [([_, err]) => !!err, () => this.$t('service.ConnectionFailed')],
        [R.T, () => this.$t('service.TestingConnection')],
      ])([this.res, this.err])
    },

    tableHeaders() {
      return [
        { text: this.$t('service.TableLogin'), value: 'login' },
        { text: this.$t('service.TableEmail'), value: 'email' },
        { text: this.$t('service.TableName'), value: 'firstName' },
        { text: this.$t('service.TableLastName'), value: 'lastName' },
        { text: this.$t('service.TableDn'), value: 'dn' },
        { text: this.$t('service.TableGroups'), value: 'groups' },
        { text: this.$t('service.TableIsAdmin'), value: 'isAdmin' },
        { text: this.$t('service.TableIsBlocked'), value: 'isBlocked' },
      ]
    },
  },

  watch: {
    value() { this.expanded = false },
  },

  methods: {
    close() { this.$emit('input', false) },
  },
}
</script>

<style lang="sass" scoped>
.LdapTestConnectionDialog
  position: relative
  padding: 82px 32px 32px 48px
  text-align: center
  max-height: 75vh
  overflow: hidden auto

  &__title
    font-weight: 500
    font-size: 24px
    line-height: 32px
    margin-bottom: 28px

    display: flex
    align-items: center
    justify-content: center
    height: 32px

  &__progress
    margin-bottom: 26px
    max-width: 456px - 48px - 32px
    margin-left: auto
    margin-right: auto

  &__res
    width: 56px
    height: 56px
    margin: 26px auto 0
    border-radius: 100%
    border: 4px solid currentColor
    color: transparent
    display: none
    align-items: center
    justify-content: center

    &--success
      display: flex
      color: #2BD99F

    &--error
      display: flex
      color: #ff5065

  &__error-message
    text-align: left
    white-space: pre-wrap
    word-break: break-word
    font-family: Consolas, monospace

    margin: 26px 0 0
    color: #ff5065

  &__success-table
    margin: 26px 0 0

    ::v-deep
      th, td
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

  &__actions
    margin-top: 58px
    text-align: right
</style>

<template>
  <AppBarLayout class="LdapConfigEditAppBar">
    <template #header>
      {{ $t('service.LdapSettings') }}
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :outlined="testConfigRes == null"
        :depressed="testConfigRes != null"
        @click="broadcast('LdapConfigEdit:save')"
      >
        {{ $t('service.Save') }}
      </v-btn>
      <v-btn
        color="primary"
        :outlined="testConfigRes != null"
        :depressed="testConfigRes == null"
        class="ml-2"
        @click="broadcast('LdapConfigEdit:test')"
      >
        {{ $t('service.TestConnection') }}
      </v-btn>
      <v-btn
        color="primary"
        outlined
        class="ml-2"
        :to="{ name: 'LdapConfig' }"
        exact
      >
        {{ $t('service.Cancel') }}
      </v-btn>
    </template>
  </AppBarLayout>
</template>

<script>
import AppBarLayout from '../layouts/AppBarLayout'

import bus from '../bus'

export default {
  name: 'LdapConfigEditAppBar',

  components: {
    AppBarLayout,
  },

  computed: {
    testConfigRes() {
      return this.$store.state.ldapConfigEditPage.testConfigRes
    },
    testConfigError() {
      return this.$store.state.ldapConfigEditPage.testConfigError
    },
  },

  methods: {
    broadcast(event, ...args) { bus.$emit(event, ...args) },
  },
}
</script>

<style lang="sass" scoped>
// .LdapConfigEditAppBar
</style>

<template>
  <v-form
    ref="form"
    class="LdapConfigEditForm"
    :disabled="disabledComputed"
    @submit.prevent="testLdapConfig"
  >
    <fieldset class="LdapConfigEditForm__fieldset">
      <legend
        class="LdapConfigEditForm__fieldset-legend"
        v-text="$t('service.Connection')"
      />
      <AppSelect
        v-model="form.protocol"
        class="LdapConfigEditForm__field"
        filled
        :items="LDAP_PROTOCOLS"
        :label="$t('service.LdapProtocol')"
        name="ldap-protocol"
        :error-messages="getErrors('protocol')"
      />

      <AppCombobox
        v-model.number="form.port"
        :items="LDAP_DEFAULT_PORTS.map(port => String(port))"
        class="LdapConfigEditForm__field"
        filled
        :label="$t('service.LdapPort')"
        placeholder="389"
        type="number"
        name="ldap-port"
        :error-messages="getErrors('port')"
      />

      <AppTextField
        v-model="form.host"
        class="LdapConfigEditForm__field"
        filled
        :label="$t('service.Host')"
        placeholder="ldap.example.com"
        name="ldap-host"
        :error-messages="getErrors('host')"
      />

      <AppTextarea
        v-model="form.baseDN"
        class="LdapConfigEditForm__field"
        filled
        :label="$t('service.BaseDn')"
        placeholder="CN=users,DC=example,DC=com"
        name="ldap-base-dn"
        auto-grow
        rows="1"
        :error-messages="getErrors('baseDN')"
      />

      <AppTextarea
        v-model="form.blockedGroupDN"
        class="LdapConfigEditForm__field"
        filled
        :label="$t('service.BlockedGroupDn')"
        placeholder="CN=Blocks,CN=users,DC=example,DC=com"
        name="ldap-blocked-group-dn"
        auto-grow
        rows="1"
        :error-messages="getErrors('blockedGroupDN')"
      />
    </fieldset>

    <fieldset class="LdapConfigEditForm__fieldset">
      <legend
        class="LdapConfigEditForm__fieldset-legend"
        v-text="$t('service.Authentication')"
      />
      <AppTextField
        v-model="form.adminLogin"
        class="LdapConfigEditForm__field"
        filled
        :label="$t('service.ServiceLogin')"
        :placeholder="'admin@' + (form.host || 'ldap.example.com')"
        name="ldap-login"
        :error-messages="getErrors('adminLogin')"
      />

      <AppTextField
        v-model="form.adminPassword"
        class="LdapConfigEditForm__field"
        filled
        :label="$t('service.ServicePassword')"
        placeholder="str0ng_p@s$w0rD"
        name="ldap-password"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :error-messages="getErrors('adminPassword')"
        @click:append="showPassword = !showPassword"
      />
    </fieldset>

    <fieldset class="LdapConfigEditForm__fieldset">
      <legend
        class="LdapConfigEditForm__fieldset-legend"
        v-text="$t('service.Users')"
      />

      <AppTextarea
        v-model="form.userFilter"
        class="LdapConfigEditForm__field"
        filled
        :label="$t('service.UserFilter')"
        placeholder="objectClass=User"
        name="ldap-user-filter"
        auto-grow
        rows="1"
        :error-messages="getErrors('userFilter')"
      />

      <AppTextField
        v-model="form.objectClass"
        class="LdapConfigEditForm__field"
        filled
        :label="$t('service.ObjectClass')"
        placeholder="user"
        name="ldap-object-class"
        :error-messages="getErrors('objectClass')"
      />

      <AppTextarea
        v-model="form.adminGroupDN"
        class="LdapConfigEditForm__field"
        filled
        :label="$t('service.AdminGroupDn')"
        placeholder="CN=users,DC=example,DC=com"
        name="ldap-admin-group-dn"
        auto-grow
        rows="1"
        :error-messages="getErrors('adminGroupDN')"
      />
      <AppTextarea
        v-model="form.editorGroupDN"
        class="LdapConfigEditForm__field"
        filled
        :label="$t('service.EditorGroupDn')"
        placeholder="CN=users,DC=example,DC=com"
        name="ldap-admin-group-dn"
        auto-grow
        rows="1"
        :error-messages="getErrors('editorGroupDN')"
      />
      <AppTextarea
        v-model="form.readonlyGroupDN"
        class="LdapConfigEditForm__field"
        filled
        :label="$t('service.ReadonlyGroupDn')"
        placeholder="CN=users,DC=example,DC=com"
        name="ldap-admin-group-dn"
        auto-grow
        rows="1"
        :error-messages="getErrors('readonlyGroupDN')"
      />
    </fieldset>

    <fieldset class="LdapConfigEditForm__fieldset">
      <legend
        class="LdapConfigEditForm__fieldset-legend"
        v-text="$t('service.UserMapping')"
      />

      <AppTextField
        v-model="form.loginAttribute"
        class="LdapConfigEditForm__field"
        filled
        :label="$t('service.LoginAttribute')"
        placeholder="sAMAccountName"
        name="ldap-login-attribute"
        :error-messages="getErrors('loginAttribute')"
      />

      <AppTextField
        v-model="form.emailAttribute"
        class="LdapConfigEditForm__field"
        filled
        :label="$t('service.EmailAttribute')"
        placeholder="userPrincipalName"
        name="ldap-email-attribute"
        :error-messages="getErrors('emailAttribute')"
      />

      <AppTextField
        v-model="form.firstNameAttribute"
        class="LdapConfigEditForm__field"
        filled
        :label="$t('service.FirstNameAttribute')"
        placeholder="givenName"
        name="ldap-first-name-attribute"
        :error-messages="getErrors('firstNameAttribute')"
      />

      <AppTextField
        v-model="form.lastNameAttribute"
        class="LdapConfigEditForm__field"
        filled
        :label="$t('service.LastNameAttribute')"
        placeholder="sn"
        name="ldap-last-name-attribute"
        :error-messages="getErrors('lastNameAttribute')"
      />
    </fieldset>

    <LdapTestConnectionDialog
      v-model="testDialog"
      :ldap-config-form="form"
    />
  </v-form>
</template>

<script>
import ldapjs from 'ldapjs'
import * as R from 'ramda'
import { required, minValue, helpers as vuelidateHelpers } from 'vuelidate/lib/validators'
import i18n from '../i18n'

import bus from '../bus'
import {
  LDAP_DEFAULT_PORTS,
  LDAP_MISSING,
  LDAP_NOT_LOADED,
  LDAP_PROTOCOLS,
  LDAP_PROTOCOL_DEFAULT_PORTS,
} from '../constants'
import { handleError, listenBeforeDestroy, pushRoute } from '../helpers'

import LdapTestConnectionDialog from '../components/LdapTestConnectionDialog'

const initForm = (ldapConfig) => ({
  protocol: LDAP_PROTOCOLS[0],
  host: '',
  port: 389,
  baseDN: '',
  blockedGroupDN: '',
  adminLogin: '',
  adminPassword: '',
  userFilter: 'objectClass=User',
  objectClass: 'user',
  adminGroupDN: '',
  editorGroupDN: '',
  readonlyGroupDN: '',
  loginAttribute: 'sAMAccountName',
  emailAttribute: 'userPrincipalName',
  firstNameAttribute: 'givenName',
  lastNameAttribute: 'sn',

  // enforce adminGroupDN as a string, workaround weird openAPI quirks
  ...(ldapConfig && R.pipe(
    R.over(R.lensProp('adminGroupDN'), R.defaultTo('')),
    R.over(R.lensProp('editorGroupDN'), R.defaultTo('')),
    R.over(R.lensProp('readonlyGroupDN'), R.defaultTo('')),
  )(ldapConfig)),
})

const validateLdapDn = v => {
  if (!vuelidateHelpers.req(v)) return true
  try {
    ldapjs.parseDN(v)
    return true
  } catch (e) {
    console.warn(e)
    return false
  }
}

const validateLdapFilter = v => {
  if (!vuelidateHelpers.req(v)) return true
  try {
    ldapjs.parseFilter(v)
    return true
  } catch (e) {
    console.warn(e)
    return false
  }
}

const validationErrors = {
  'protocol-required': i18n.t('service.validationErrors.ProtocolRequired'),
  'host-required': i18n.t('service.validationErrors.HostRequired'),
  'port-required': i18n.t('service.validationErrors.PortRequired'),
  'port-positive': i18n.t('service.validationErrors.PortPositive'),
  'baseDN-required': i18n.t('service.validationErrors.BaseDnRequired'),
  'baseDN-format': i18n.t('service.validationErrors.BaseDnFormat'),
  'blockedGroupDN-format': i18n.t('service.validationErrors.BlockedGroupDnFormat'),
  'adminLogin-required': i18n.t('service.validationErrors.AdminLoginRequired'),
  'adminPassword-required': i18n.t('service.validationErrors.AdminPasswordRequired'),
  'userFilter-required': i18n.t('service.validationErrors.UserFilterRequired'),
  'userFilter-format': i18n.t('service.validationErrors.UserFilterFormat'),
  'objectClass-required': i18n.t('service.validationErrors.ObjectClassRequired'),
  'adminGroupDN-format': i18n.t('service.validationErrors.AdminGroupDnFormat'),
  'editorGroupDN-format': i18n.t('service.validationErrors.EditorGroupDnFormat'),
  'readonlyGroupDN-format': i18n.t('service.validationErrors.ReadonlyGroupDnFormat'),
  'loginAttribute-required': i18n.t('service.validationErrors.UserLoginRequired'),
  'emailAttribute-required': i18n.t('service.validationErrors.UserEmailRequired'),
  'firstNameAttribute-required': i18n.t('service.validationErrors.FirstNameRequired'),
  'lastNameAttribute-required': i18n.t('service.validationErrors.LastNameRequired'),
}

export default {
  name: 'LdapConfigEditForm',

  components: {
    LdapTestConnectionDialog,
  },

  props: {
    disabled: { type: Boolean, default: false }, // sync.
  },

  data() {
    return {
      LDAP_DEFAULT_PORTS,
      LDAP_MISSING,
      LDAP_NOT_LOADED,
      LDAP_PROTOCOLS,

      form: initForm(),
      saving: false,
      testConfigPending: false,
      testDialog: false,

      showPassword: false,
    }
  },

  validations: {
    form: {
      protocol: { required },
      host: { required },
      port: { required, positive: minValue(1) },
      baseDN: { required, format: validateLdapDn },
      blockedGroupDN: { format: validateLdapDn },
      adminLogin: { required },
      adminPassword: { required },
      userFilter: { /* required, */ format: validateLdapFilter },
      objectClass: {/* required */},
      adminGroupDN: { format: validateLdapDn },
      editorGroupDN: { format: validateLdapDn },
      readonlyGroupDN: { format: validateLdapDn },
      loginAttribute: {/* required */},
      emailAttribute: {/* required */},
      firstNameAttribute: {/* required */},
      lastNameAttribute: {/* required */},
    },
  },

  computed: {
    ldapConfig() { return this.$store.state.service.ldapConfig },

    testConfigRes: {
      get() { return this.$store.state.ldapConfigEditPage.testConfigRes },
      set(testConfigRes) {
        const { $store } = this
        $store.commit('ldapConfigEditPage/setTestConfigRes', testConfigRes)
      },
    },

    testConfigError: {
      get() { return this.$store.state.ldapConfigEditPage.testConfigError },
      set(testConfigError) {
        const { $store } = this
        $store.commit('ldapConfigEditPage/setTestConfigError', testConfigError)
      },
    },

    disabledComputed() { return this.disabled || this.saving || this.testConfigPending },
  },

  watch: {
    ldapConfig: {
      handler(ldapConfig) {
        this.form = initForm(ldapConfig)
      },
      immediate: true,
    },

    'form.protocol'(protocol) {
      const defaultPort = LDAP_PROTOCOL_DEFAULT_PORTS[protocol]
      if (defaultPort != null) this.form.port = defaultPort
    },

    form: {
      handler() { this.testConfigRes = null },
      deep: true,
    },

    disabledComputed(disabled) { this.$emit('update:disabled', !!disabled) },
  },

  created() {
    this.setupWatchers()
  },

  methods: {
    setupWatchers() {
      listenBeforeDestroy(
        this,
        bus,
        'LdapConfigEdit:test',
        () => this.testLdapConfig(),
      )
      listenBeforeDestroy(
        this,
        bus,
        'LdapConfigEdit:save',
        () => this.saveConnection(),
      )
    },

    getErrors(field) {
      const v = this.$v.form[field]

      if (!v.$dirty) return []
      return Object.entries(v)
        .filter(([k, _]) => !k.startsWith('$'))
        .filter(([_, v]) => !v)
        .map(([k, _]) =>
          validationErrors[`${field}-${k}`] ||
          `${this.$t('service.validationErrors.ValidationError')} ${field} ${k}`)
    },

    async testLdapConfig() {
      await this.$nextTick()

      const { $store, form } = this
      this.$v.$touch() // highlight errors
      if (this.$v.$error) return

      this.testConfigRes = this.testConfigError = null
      this.testConfigPending = true
      this.testDialog = true
      try {
        this.testConfigRes = await $store.dispatch(
          'service/testLdapConfig',
          {
            ldapConfig: { ...form, enabled: true },
          },
        )
      } catch (e) {
        this.testConfigError = e?.response?.data?.detail || this.$t('service.ErrorConnection')
        if (e?.response?.status === 461 || !this.testConfigError) {
          return handleError($store, e)
            .catch(() => {})
        }
        if (R.is(String, this.testConfigError)) {
          try {
            this.testConfigError = JSON.parse(this.testConfigError)
          } catch (e) {
            console.error(e)
          }
        }
      } finally {
        this.testConfigPending = false
        this.testDialog = true
        // this.step = STEPS.TEST_RESULTS
      }
    },

    async saveConnection() {
      await this.$nextTick()

      const { $store, form, ldapConfig, testConfigRes } = this
      this.$v.$touch() // highlight errors
      if (this.$v.$error) return

      let agreed = false
      if (testConfigRes) {
        // connection was tested to work
        agreed = await $store.dispatch('confirm/openDialog', {
          title: this.$t('service.SaveConfigurationQ'),
          subtitle: this.$t('service.ConnectionWillBeUsedM'),
          consentLabel: this.$t('service.Save'),
        })
      } else {
        agreed = await $store.dispatch('confirm/openDialog', {
          title: this.$t('service.SaveConfigurationQ'),
          subtitle: this.$t('service.ConnectionNotTestedM'),
          consentLabel: this.$t('service.SaveAnyway'),
        })
      }
      if (!agreed) return

      this.saving = true
      try {
        await $store.dispatch(
          ldapConfig === LDAP_MISSING
            ? 'service/createLdapConfig'
            : 'service/updateLdapConfig',
          {
            ldapConfig: { ...form, enabled: true },
          },
        )
        this.$v.form.$reset()
        pushRoute(this.$router, { name: 'LdapConfig' })
      } catch (e) {
        return console.error(e)
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.LdapConfigEditForm
  max-width: 520px

  &__fieldset
    border: none
    margin-top: 16px

  &__fieldset-legend
    border: none
    font-weight: 500
    font-size: 20px
    line-height: 32px
    letter-spacing: 0.015em
    margin-bottom: 16px

  &__field
    flex: 0 0 calc(50% - 24px)
</style>

<template>
  <div class="LdapConfigEdit">
    <LdapConfigEditAppBar />

    <div class="LdapConfigEdit__page">
      <LdapConfigEditForm />
    </div>
  </div>
</template>

<script>
import { LDAP_MISSING, LDAP_NOT_LOADED } from '../constants'

import LdapConfigEditAppBar from '../components/LdapConfigEditAppBar'
import LdapConfigEditForm from '../components/LdapConfigEditForm'

export default {
  name: 'LdapConfigEdit',

  components: {
    LdapConfigEditAppBar,
    LdapConfigEditForm,
  },

  metaInfo() {
    return {
      title: this.$store.getters.title(this.$t('service.ConfigureLdap')),
    }
  },

  data() {
    return {
      LDAP_MISSING,
      LDAP_NOT_LOADED,
    }
  },

  computed: {
    ldapConfig() { return this.$store.state.service.ldapConfig },
  },

  created() {
    this.$store.dispatch('service/getLdapConfig')
  },

  methods: {
  },
}
</script>

<style lang="sass">
.LdapConfigEdit
  height: 100%
  padding: 64px
  display: flex
  justify-content: center
  background: white

  &__page
    flex: 0 1 1152px

  &__title
    margin: 0 0 34px
</style>
